import { Suspense, FC  } from "react";
import { useState } from "react";
import iconMaker from "./iconMaker";

// 返り値の型
interface MemberInfo{
  id: number;
  name: string;   
  type: any;
}

let result: string = "";

const IconMaker = (props: { memberInfo: MemberInfo }) => {
  const [iconData, setIconData] = useState("");
  
  if (!result) {
    iconMaker(props.memberInfo).then((r) => {
      result = r;
      setIconData(result);
    });
  }
  
  return <img alt="icon" src={iconData} />;
};

const NameIcon: FC<{ memberInfo: MemberInfo; }> = ({ memberInfo }) => {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <IconMaker memberInfo={memberInfo} />
    </Suspense>
  );
};

export default NameIcon;

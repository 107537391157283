import { useRecoilState } from 'recoil';
import { userState } from '../../../store/userState';
import { memberState } from '../../../store/memberState';
import { Button } from "@material-ui/core";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

export const AddButton = () => {
    const [userInfo, setUserInfo] = useRecoilState(userState);
    const [memberInfo, setMemberInfo] = useRecoilState(memberState);
    const classes = useStyles();
    // テキストボックスの値が空かどうかの判定
    const isButtonDisabled = userInfo.name === ''; 
    //名前とtypeをメンバーリストに格納
    const onClickAddMemberList = async() => {
        //テキストボックスが空の時に追加ボタンのonclick無効
        if (isButtonDisabled) return;
        //同じ名前が同じ属性で入力されたときに警告
        const hasMatchingElement = memberInfo.some(member => member.name === userInfo.name && member.type === userInfo.type);
        if (hasMatchingElement){
          alert('入力されたメンバーは既に登録済みです。');
          setUserInfo(userName => ({...userName, name: ''}))
          return;
        }
        //テキストボックスの名前をユーザーリストに格納
        setMemberInfo([...memberInfo, userInfo])
        setUserInfo(memberID => ({...memberID, id: userInfo.id+1}))
        setUserInfo(userName => ({...userName, name: ''}))
    };

    return(
        <Button
          variant="contained"
          className={isButtonDisabled ? classes.disabledButton : classes.button}
          TouchRippleProps={{ center: true }} 
          endIcon={<AddCircleIcon />}
          onClick={onClickAddMemberList}
          disabled={isButtonDisabled}
      >
        追加
      </Button>
    )
}

const useStyles = makeStyles((theme) => ({
  button: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#1976d2',
      color: theme.palette.common.white,
  },
  disabledButton: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#ccc',
    color: theme.palette.common.white,
  },
  buttonText: {
      textTransform: 'lowercase',
      fontSize: '20px',
  },
}));

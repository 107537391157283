//import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Refresh from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import { memberState } from '../../../store/memberState';

export const RandomArangementButton = () => {
  const classes = useStyles();
  const overwritePersistData = (newData: any) => {
    // sessionStorageからrecoil-persistデータを取得
    const recoilPersistData = sessionStorage.getItem('recoil-persist');
    if (recoilPersistData) {
      // recoil-persistデータをパースしてオブジェクトに変換
      const sessionStorageData = JSON.parse(recoilPersistData);
      // newDataの値を上書き
      sessionStorageData.memberState = newData;
      // 更新されたrecoil-persistデータをsessionStorageに保存
      sessionStorage.setItem('recoil-persist', JSON.stringify(sessionStorageData));
    }
  }
  const [memberInfo] = useRecoilState(memberState);
  // reloadメソッドによりページをリロード
  const onClickReload = () => {
    const newMemberInfo = [...memberInfo];
    const isTouched = sessionStorage.getItem('isTouched');
    if(isTouched === "false"){
      for (let i = newMemberInfo.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newMemberInfo[i], newMemberInfo[j]] = [newMemberInfo[j], newMemberInfo[i]];
      }
    }
    // ランダムで並び替えたメンバーリストをlocalStorageのrecoil-persistに更新
    overwritePersistData(newMemberInfo);
    window.location.reload();
  }
  return(
    <Button 
      variant="contained"
      className={classes.button}
      TouchRippleProps={{ center: true }} 
      startIcon={<Refresh />}
      onClick={onClickReload}
      >やり直し
  </Button> 
    );
};

const useStyles = makeStyles((theme) => ({
  button: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#1976d2',
      color: theme.palette.common.white,
  },
  buttonText: {
      textTransform: 'lowercase',
      fontSize: '10px',
  },
}));

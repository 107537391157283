import React from 'react';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Setting } from './components/pages/Setting';
import { RecoilRoot } from 'recoil';
import { Result } from './components/pages/Result';

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <Routes>
          <Route path="/home" element={<Home/>} />
          <Route path="/setting" element={<Setting/>} />
          <Route path="/party/:partyId" element={<Result />} /> 
          <Route path="/" element={<Home/>} />
          <Route path="*" element={<Home/>} />
        </Routes>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
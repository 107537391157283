import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { useRecoilState } from 'recoil';
import { memberState } from '../../../store/memberState';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const RegisteredMemberButton = () => {
  //recoilによるメンバーリストのグローバルステート
  const [memberInfo, setMemberInfo] = useRecoilState(memberState);
  //登録リストのchipの削除関数
  const handleDelete = (chipToDelete: any) => () => {
    setMemberInfo((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
        boxShadow: 'none',
      }}
      component="ul"
    >
      {memberInfo.map((data) => {
        let icon;
        return (
          <ListItem key={data.id}>
            <Chip
              icon={icon}
              label={data.name}
              color={data.type}
              onDelete={handleDelete(data)}
            />
          </ListItem>
        );
      })}
    </Paper>
  );
}

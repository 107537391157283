import { ChangeEvent, memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { red, blue, green, yellow, grey} from '@material-ui/core/colors';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { useRecoilState } from 'recoil'
import { userState } from '../../../store/userState';

//ラジオボタンの各色の設定
const RedRadio = withStyles({
    root: {
      color: red[400],
      '&$checked': {
        color: red[600],
      },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const BlueRadio = withStyles({
    root: {
      color: blue[400],
      '&$checked': {
        color: blue[600],
      },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const YellowRadio = withStyles({
    root: {
      color: yellow[700],
      '&$checked': {
        color: yellow[600],
      },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const GreyRadio = withStyles({
    root: {
      color: grey[600],
      '&$checked': {
        color: grey[600],
      },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export const RadioButton = memo(() => {
  const [userInfo, setUserInfo] = useRecoilState(userState);
  //ユーザーの属性をユーザーリストに格納
  const setUserType = (event: ChangeEvent<HTMLInputElement>) => {
    setUserInfo(userType => ({...userType, type: event.target.value}));
  };

  return (
    <div>
      <BlueRadio
        checked={userInfo.type === 'primary'}
        onChange={setUserType}
        value="primary"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'B' }}
      />
      <RedRadio
        checked={userInfo.type === 'error'}
        onChange={setUserType}
        value="error"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'A' }}
      />
      <GreenRadio
        checked={userInfo.type === 'success'}
        onChange={setUserType}
        value="success"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'C' }}
      />
      <YellowRadio
        checked={userInfo.type === 'warning'}
        onChange={setUserType}
        value="warning"
        color="default"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'D' }}
      />
      <GreyRadio
        checked={userInfo.type === 'default'}
        onChange={setUserType}
        value="default"
        color="default"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'E' }}
      />
    </div>
  );
})
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CheckIcon from '@mui/icons-material/Check';

export const CopyUrlButton = () => {
  const classes = useStyles();
  // コピーの状態を管理
  const [isCopied, setIsCopied] = useState(false); 

  const copyUrl = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    // コピー完了後に状態を更新
    setIsCopied(true); 
  };

  return (
    <Button 
      variant="contained"
      className={`${classes.button} ${isCopied ? classes.copiedButton : ''}`}
      TouchRippleProps={{ center: true }} 
      onClick={copyUrl}
      startIcon={isCopied ? <CheckIcon /> : <CopyAllIcon />}
      style={{ margin: '10px' }}
    >
      {isCopied ? '完了' : 'URL'}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    color: theme.palette.common.white,
  },
  copiedButton: {
    backgroundColor: '#1976d2',
  },
  buttonText: {
    textTransform: 'lowercase',
    fontSize: '10px',
  },
}));

import { Suspense  } from "react";
import ResponsiveDrawer from '../layout/ResponsiveDrawer';
import { TransitionButton } from '../atoms/button/TransitionButton';
import styled from "@emotion/styled"
import homeImage from '../../sekigimeHome.png';
import drinkImage from '../../drinkImage.png';
import fixImage from '../../gesture01.webp';
import iphoneImage from '../../iphoneImage.png';

const Home = () => {
  sessionStorage.removeItem("recoil-persist");
  // sessionStorage.removeItem("new-recoil-persist");
  return (
    <ResponsiveDrawer>
      <StyledContainer>
        <StyledSmollText>
          飲み会や会食での席決めや席変えに
        </StyledSmollText>
        <StyledLargeText>
          Sekigime
        </StyledLargeText>
        <Suspense fallback={<p>Loading...</p>}>
          <img src={homeImage} alt="Sekigime Home" style={{ width: '250px', height: 'auto' }} />
        </Suspense>
        <TransitionButton buttonName="はじめる" linkName='/setting'/>
      </StyledContainer>
      <br />
      <StyledExplanation>
        <br />
        <p style={{ fontWeight: 'bold'}}>Sekigimeの特徴</p>

        <Suspense fallback={<p>Loading...</p>}>
          <img src={drinkImage} alt="Sekigime Home" style={{ width: '200px', height: 'auto' }} />
        </Suspense>
        <h3>簡単に飲み会の席を決定</h3>
        <StyledExplanationText>
          設定したグループ毎に<br/>
          席をランダムに配置することができます。<br/>
        </StyledExplanationText>
        <br/>

          
        <Suspense fallback={<p>Loading...</p>}>
          <img src={fixImage} alt="Fix Home" style={{ width: '200px', height: 'auto' }} />
        </Suspense>
        <h3>配置後の席を微修正</h3>
          <StyledExplanationText>
            ちょっとした変更をしたい場合<br/>
            微修正をすることができます。<br/>
          </StyledExplanationText>
        <br/>


        <Suspense fallback={<p>Loading...</p>}>
          <img src={iphoneImage} alt="Sekigime Home" style={{ width: '200px', height: 'auto' }} />
        </Suspense>
        <h3>登録不要で簡単に利用</h3>
          <StyledExplanationText>
            アプリのインストール不要で<br/>
            ブラウザからすぐに利用できます。<br/>
          </StyledExplanationText>
          <br />
      </StyledExplanation>
    </ResponsiveDrawer>
  );
}
export default Home;


const StyledSmollText = styled.div`
  font-size: 15px;
  font-family: 'Noto Sans Japanese', sans-serif;
  margin-bottom: 10px;
`;

const StyledLargeText = styled.div`
  font-size: 40px;
  font-family: 'Intro', sans-serif;
  margin-bottom: 10px;

`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1976d2;
  flex-direction: column;
`;


const StyledExplanation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1976d2;
  background-color: #E1F5FE;
  flex-direction: column;
`;

const StyledExplanationText = styled.div`
  text-align: center;
`;
import { Suspense  } from "react";
import { RegisteredMemberButton } from "../atoms/button/RegisteredMemberButton";
import ResponsiveDrawer from "../layout/ResponsiveDrawer"
import { InputArea } from "../organisms/InputArea";
import styled from "@emotion/styled"
import { TransitionButton } from "../atoms/button/TransitionButton";

export const Setting = () => {
    return(
    <ResponsiveDrawer>
      <Suspense fallback={<p>Loading...</p>}>
          <InputArea />
          <RegisteredMemberButton />
          <SInputArea>
              <TransitionButton buttonName="結果" linkName="/party"/>
          </SInputArea>
      </Suspense>
    </ResponsiveDrawer>
    )
}


const SInputArea = styled.div`
  display: flex;
  align-items: center;
	justify-content: center;
  padding: 1em;
`
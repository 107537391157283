import { Button } from "@material-ui/core";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { makeStyles } from '@material-ui/core/styles';

export const BackButton = () => {
    const classes = useStyles();
    const handleGoBack = () => {
      // 前のページに戻る処理を実装する
      window.history.back();
    };
    return (
      <Button 
        variant="contained"
        className={classes.button}
        TouchRippleProps={{ center: true }} 
        startIcon={<ArrowCircleLeftIcon />}
        onClick={handleGoBack}
        >戻る
      </Button>
    );
};

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#1976d2',
        color: theme.palette.common.white,
    },
    buttonText: {
        textTransform: 'lowercase',
        fontSize: '50px',
    },
}));

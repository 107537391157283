// import React, { memo, useEffect, useState } from "react";
import React, { memo, useState } from "react";
import ResponsiveDrawer from "../layout/ResponsiveDrawer"
import { useDnDSort } from "../templates/useDnDSort";
import { useRecoilState } from "recoil";
import { memberState } from "../../store/memberState";
import styled from "@emotion/styled"
import { RandomArangementButton } from "../atoms/button/RandomArangementButton";
import { BackButton } from "../atoms/button/BackButton";
import NameIcon from "../templates/NameIcon";
// import db from "../../firebase";
// import { collection, getDocs } from "firebase/firestore";
// import { useParams } from 'react-router-dom';
import {CopyUrlButton} from "../atoms/button/CopyUrlButton";


type Style<T extends HTMLElement> = React.HTMLAttributes<T>["style"];
// 返り値の型
interface MemberInfo{
  id: number;
  name: string;   
  type: any;
  position: any;
}

/**
 * @description ドラッグ＆ドロップ並び替えサンプルのコンポーネント
 */
export const Result = memo(() => {
  const [memberInfo] = useRecoilState(memberState);
  // URLからパーティードキュメントIDを取得
  // firestore使用時のコード
  // const { partyId } = useParams();
  // useEffect(() => {
  //   const fetchMemberInfo = async (partyId: any): Promise<MemberInfo[]> => {
  //     try {
  //       const memberInfoCollectionRef = collection(db, "party", partyId, "memberInfo");
  //       const querySnapshot = await getDocs(memberInfoCollectionRef);
  //       const memberInfoArray: MemberInfo[] = querySnapshot.docs.map((doc) => doc.data() as MemberInfo);
  //       return memberInfoArray;
  //     } catch (error) {
  //       console.error("データの取得に失敗しました", error);
  //       return [];
  //     }
  //   };
  //   fetchMemberInfo(partyId).then((data) => {
  //     setMemberInfo(data);
  //   }).catch((error) => {
  //       console.error("データの取得に失敗しました", error);
  //   });
  // }, [partyId, setMemberInfo]);

  const memberInfoSort = (memberInfo:any) => {
    // タイプごとに分類するためのオブジェクト
    const typeGroups:any = {};
    // タイプごとに分類してオブジェクトに追加
    memberInfo.forEach((item:any) => {
      const type = item.type;
      if (!typeGroups[type]) {
        typeGroups[type] = [];
      }
      typeGroups[type].push(item);
    });
    // タイプごとの配列を作成
    const typeArrays:any = Object.values(typeGroups);
    // タイプ数の多い順に typeArrays をソート
    typeArrays.sort((a:any, b:any) => b.length - a.length);
    // すべてのタイプ配列の最大の長さを見つける
    const maxTypeArrayLength = typeArrays[0].length;
    // 交互に要素を配置するための結果の配列
    const resultArray:MemberInfo[] = [];
    for (let i = 0; i < maxTypeArrayLength; i++) {
      typeArrays.forEach((typeArray:any) => {
        if (i < typeArray.length) {
          resultArray.push(typeArray[i]);
        }
      });
    }
    return resultArray
  }
  //名前リストを種類ごとにソート
  const memberInfoSortList = memberInfoSort(memberInfo)
  //名前リストをソート
  const results = useDnDSort(memberInfoSortList);

  const [entryPosition, setEntryPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const handleTouchStart = (event: React.TouchEvent<HTMLElement>) => {
    // タッチが始まったときの処理
    // 例: タッチ座標の初期位置を設定
    const touch = event.touches[0];
    const { clientX, clientY } = touch;
    setStartPosition({ x: clientX, y: clientY });
    // スクロールを停止
    document.body.style.overflow = 'hidden';


  };
  
  const handleTouchMove = (event: React.TouchEvent<HTMLElement>) => {
    // タッチ中に移動したときの処理
    // 例: タッチ座標に応じて位置を更新
    const touch = event.touches[0];
    const { clientX, clientY } = touch;
    // マウスポインターの移動量を計算
    const x = clientX - startPosition.x + entryPosition.x;
    const y = clientY - startPosition.y + entryPosition.y;
    event.currentTarget.style.transform = `translate(${x}px,${y}px)`;
  };
  
  const handleTouchEnd = (event: React.TouchEvent<HTMLElement>) => {
    // タッチが終了したときの処理
    // タッチ終了時に現在の位置を初期位置として設定
    setEntryPosition({
      x: event.currentTarget.style.transform ? parseInt(event.currentTarget.style.transform.split('(')[1].split('px')[0]) : 0,
      y: event.currentTarget.style.transform ? parseInt(event.currentTarget.style.transform.split(',')[1].split('px')[0]) : 0,
    });
    // スクロールを有効に戻す
    document.body.style.overflow = 'auto';
  };
  

  return (
    <ResponsiveDrawer>
      <SbodyStyle>
        <div style={buttonContainerStyle}>
          <BackButton/>
          <CopyUrlButton />
          <RandomArangementButton />
        </div>
        <div style={containerStyle}>
            {results.map((item, index) => (
              index % 2 === 0
                ?<div key={item.key} style={{ ...imageCardStyle, opacity: item.isTouched ? 0.5 : 1 }} {...item.events}>
                    <NameIcon memberInfo={item.value} />
                </div>
                :<div key={item.key} style={{ ...imageCardStyleRight, opacity: item.isTouched ? 0.5 : 1 }} {...item.events}>
                    <NameIcon memberInfo={item.value} />
                </div>
              ))}
            <div style={table}></div>
        </div>
        <div 
          style={{ ...entranceStyle}}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        ></div>
      </SbodyStyle>
    </ResponsiveDrawer>
  );
});

const SbodyStyle = styled.div`
    height: 100vh,
    display: flex,
    alignItems: center,
    justifyContent: center,
`
const containerStyle: Style<HTMLDivElement> = {
  width: "100%",
  backgroundColor: "antiquewhite",
  maxWidth: "350px",
  maxHeight: "auto",
  height: "auto",
  margin: "auto",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "space-between"
};

const imageCardStyle: Style<HTMLDivElement> = {
  cursor: "grab",
  userSelect: "none",
  width: "60px",
  height: "60px",
  overflow: "hidden",
  borderRadius: "100px",
  display: "flex",
  // デフォルトの不透明度を設定
  opacity: 1, 
};

const imageCardStyleRight: Style<HTMLDivElement> = {
  cursor: "grab",
  userSelect: "none",
  width: "60px",
  height: "60px",
  overflow: "hidden",
  borderRadius: "100px",
  display: "flex",
  alignSelf: "flex-end",
  position:"relative",
  bottom:"60px",
  // デフォルトの不透明度を設定
  opacity: 1, 
};

const table: React.CSSProperties = {
  width: "60%",
  height: "100%",
  left: "20%",
  position: "absolute",
  backgroundColor: "rgba(49, 23, 1, 0.623)",
  background: `repeating-radial-gradient(circle at -1000% 0%, rgba(116, 77, 48, 0.7), #573216 7.5%, rgba(116, 77, 48, 0.9) 10%), repeating-radial-gradient(circle at -1000% 0%, #573216, #573216 0.1%, #744d30 0.4%, #744d30 0.5%)`
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
};

const entranceStyle: React.CSSProperties = {
  // 他のスタイルプロパティ...
  width: '70px',
  height: '70px',
  backgroundImage: `url(/door.png)`, // 画像のパス
  backgroundSize: 'cover', // 画像サイズの調整方法
  // 他のスタイルプロパティ...
};

// const circle: React.CSSProperties = {
//   width: "60%",
//   height: "100%",
//   left: "20%",
//   position: "absolute",
//   backgroundColor: "rgba(49, 23, 1, 0.623)",
//   borderRadius: "50%",
//   background: `repeating-radial-gradient(circle at -1000% 0%, rgba(116, 77, 48, 0.7), #573216 7.5%, rgba(116, 77, 48, 0.9) 10%), repeating-radial-gradient(circle at -1000% 0%, #573216, #573216 0.1%, #744d30 0.4%, #744d30 0.5%)`
// };


// const image: React.CSSProperties = {
//   width: '400px',
//   aspectRatio: '1',
//   borderRadius: '50%',
//   border: 'dashed 1px #777',
//   margin: '50px',
//   position: 'relative',
// };


// const imageItem: React.CSSProperties = {
//   width: '80px',
//   height: '80px',
//   borderRadius: '50%',
//   background: '#777',
//   fontSize: '32px',
//   fontWeight: '700',
//   color: '#fff',
//   lineHeight: '80px',
//   textAlign: 'center',
//   position: 'absolute',
//   WebkitTransform: 'translate(-50%, -50%)',
//   transform: 'translate(-50%, -50%)',
// };



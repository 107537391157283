// import React, { useRef, useState, useEffect } from "react";
import React, { useRef, useState } from "react";
// import { getFirestore, doc, collection, onSnapshot, Unsubscribe, getDoc, updateDoc, query } from "firebase/firestore";


/**
 * @description マウスポインターが要素と被っているか判定
 */
const isHover = (event: TouchEvent, element: HTMLElement): boolean => {
  // マウスポインターの座標を取得
  const clientX = event.touches[0].clientX;
  const clientY = event.touches[0].clientY;
  // 重なりを判定する要素のサイズと座標を取得
  const rect = element.getBoundingClientRect();
  // マウスポインターが要素と重なっているかを判定する
  return (
    clientY < rect.bottom &&
    clientY > rect.top &&
    clientX < rect.right &&
    clientX > rect.left
  );
};

// 座標の型
interface Position {
  x: number;
  y: number;
}

// ドラッグ＆ドロップ要素の情報をまとめた型
interface DnDItem<MemberInfo> {
  value: MemberInfo; // useDnDSort()の引数に渡された配列の要素の値
  key: string; // 要素と紐づいた一意な文字列
  position: Position; // 要素の座標
  element: HTMLElement; // DOM情報
}

// useRef()で保持するデータの型
interface DnDRef<T> {
  keys: Map<T, string>; // 要素に紐づいたkey文字列を管理するMap
  dndItems: DnDItem<T>[]; // 並び替える全ての要素を保持するための配列
  canCheckHovered: boolean; // 重なり判定ができるかのフラグ
  pointerPosition: Position; // マウスポインターの座標
  dragElement: DnDItem<T> | null; // ドラッグしてる要素
}

// 返り値の型
interface DnDSortResult<MemberInfo> {
  key: string;
  value: MemberInfo;
  isTouched: boolean; //Touchしているかの判定
  events: {
    ref: (element: HTMLElement | null) => void;
    onTouchStart: (event: React.TouchEvent) => void;
    onTouchEnd: (event: React.TouchEvent) => void;
    onTouchMove: (event: React.TouchEvent<HTMLElement>) => void;
  };
}

// 【firestore:保存】他ユーザの画面でも並び替え後の位置情報が反映されるようにDBにMemberInfoの位置座標を保存
// const savePositionToFirestore = async ( dndItems: any, isTouched: any) => {
//   const db = getFirestore();
//   // 現在のURLのパスを取得
//   const currentPath = window.location.pathname;
//   const partyIndex = currentPath.indexOf("party");
//   const partyId = currentPath.slice(partyIndex + "party/".length);
//   const partyDocRef = doc(db, "party", partyId as string);

//   // MemberInfo配列の各ユーザ情報をドキュメントとして格納
//   const memberInfoCollectionRef = collection(partyDocRef, "memberInfo");
//   for (const data of dndItems) {
//     const memberInfoDocRef = doc(memberInfoCollectionRef, data.value.id.toString());
//     const index = dndItems.findIndex((item:any) => item.value.id === data.value.id);
//     // 誰もタッチしてない時だけDBへの更新（=他ユーザの位置更新反映）がされるようにする
//     updateDoc(memberInfoDocRef, {"index": index})
//   }
// };

// Firestoreのドキュメントを監視して座標の情報を取得
// const watchPositionFromFirestore = (
//   key: string,
//   state: any,
//   setMemberInfo:any
// ): Unsubscribe => {
//     let { dndItems, } = state;
//     const db = getFirestore();
//       // 現在のURLのパスを取得
//     const currentPath = window.location.pathname;
//     const partyIndex = currentPath.indexOf("party");
//     const partyId = currentPath.slice(partyIndex + "party/".length);
//     const partyDocRef = doc(db, "party", partyId as string);
//     const memberInfoCollectionRef = collection(partyDocRef, "memberInfo");
//     // memberInfoコレクション内のドキュメントを監視
//     const q = query(memberInfoCollectionRef);
//     const unsubscribe = onSnapshot(q, querySnapshot => {
//       querySnapshot.docChanges().forEach(change => {
//         if (change.type === "modified") {
//           const newData = change.doc.data();
//           const documentId = change.doc.id;    
//           // 個別のドキュメントの情報を別途取得
//           const docRef = doc(memberInfoCollectionRef, documentId);
//           // 動かしているユーザはDBからの値を受け取って都度更新されないよう、sessionStorageのタッチ状態を使用
//           if(sessionStorage.getItem('isTouched') === "false"){
//             getDoc(docRef).then(snapshot => {
//               const oldData = snapshot.data();
//               if (oldData && newData.index !== oldData.index) {
//                 // indexフィールドが変更された場合の処理
//                 const newIndexValue = newData.index;
//                 const oldIndexValue = oldData.index;
//                 // 要素を入れ替える
//                 let temp = dndItems[newIndexValue];
//                 dndItems[newIndexValue] = dndItems[oldIndexValue];
//                 dndItems[oldIndexValue] = temp;
//               }
//             });
//           }
//         }
//       });
//       // 再描画する
//       setMemberInfo(dndItems.map((v:any) => v.value));
//     });
//   // ドキュメントの監視を停止するための関数を返す
//   return unsubscribe;
// };

// // 他のユーザのドラッグ中の要素の位置情報を取得して、画面の要素の位置を連動させる
// const usePositionSync = (key: string, elementRef: React.RefObject<HTMLElement | null>, state: any, setMemberInfo:any): void => {
//   useEffect(() => {
//     const unsubscribe = watchPositionFromFirestore(key, state, setMemberInfo);
//     return () => {
//       unsubscribe();
//     };
//   }, [key, setMemberInfo, state]);
// };

/**
 * @description ドラッグ＆ドロップの並び替え処理を提供します
 */
export const useDnDSort = <MemberInfo>(defaultMemberInfo: MemberInfo[]): DnDSortResult<MemberInfo>[] => {
  // 描画内容と紐づいているのでuseStateで管理する
  const [memberInfo, setMemberInfo] = useState(defaultMemberInfo);
  // Touchしているかの判定をuseStateで管理する
  const [isTouched, setIsTouched] = useState(false);
  // const [isPosition, setIsPosition] = useState(false);
  // 状態をrefで管理する
  const state = useRef<DnDRef<MemberInfo>>({
    dndItems: [],
    keys: new Map(),
    dragElement: null,
    canCheckHovered: true,
    pointerPosition: { x: 0, y: 0 }
  }).current;

  // 【sessionstorage:保存】dndItems配列内の要素のpositionをrecoil-persistデータに代入する関数
  const updatePositionInSessionStorage = (dndItems: any) => {
    const recoilPersistData = sessionStorage.getItem('recoil-persist');
    // sessionStorageからrecoil-persistデータを取得
    if (recoilPersistData) {
      // recoil-persistデータをパースしてオブジェクトに変換
      const sessionStorageData = JSON.parse(recoilPersistData);
      // dndItems配列をループ
      for (const item of dndItems) {
        // dndItemsの要素のidを取得
        const itemId = item.value.id;
        // recoil-persistデータからidが一致する要素を探す
        const persistedItem = sessionStorageData.memberState.find((persistedItem: any) => persistedItem.id === itemId);
        // 一致する要素が見つかった場合はpositionを更新
        if (persistedItem) {
          // persistedItem.position = item.position;
          persistedItem.position = { ...item.position };
        }
      }
      // 更新されたrecoil-persistデータをsessionStorageに保存
      sessionStorage.setItem('recoil-persist', JSON.stringify(sessionStorageData));
    }
  };

  // 要素の参照を作成
  // const elementRef = useRef<HTMLElement>(null);
  // 【firestore:監視】他のユーザのドラッグ中の要素の位置情報を取得して、画面の要素の位置を連動させる
  // usePositionSync("element1", elementRef, state, setMemberInfo);
  // タッチ開始した時の処理
  const onTouchStart = (event: TouchEvent) => {
    event.preventDefault();
  };

  // ドラッグ中の処理
  const onTouchMove = (event: TouchEvent) => {
    // 最初のタッチの情報を取得
    const touch = event.touches[0];
    // タッチされた座標を取得
    const { clientX, clientY } = touch;
    const { dndItems, dragElement, pointerPosition } = state;
    // ドラッグして無ければ何もしない
    if (!dragElement) return;
    // マウスポインターの移動量を計算
    const x = clientX - pointerPosition.x;
    const y = clientY - pointerPosition.y;
    const dragStyle = dragElement.element.style;
    // ドラッグ要素の座標とスタイルを更新
    dragStyle.zIndex = "100";
    dragStyle.cursor = "pointer";
    dragStyle.transition = "all 300ms";
    dragStyle.transform = `translate(${x}px,${y}px)`;
    // まだ確認できない場合は処理を終了する
    if (!state.canCheckHovered) return;
    // 確認できないようにする
    state.canCheckHovered = false;
    // 300ms後に確認できるようにする
    setTimeout(() => (state.canCheckHovered = true), 300);
    // ドラッグしている要素の配列の位置を取得
    const dragIndex = dndItems.findIndex(({ key }) => key === dragElement.key);
    // ホバーされている要素の配列の位置を取得
    const hoveredIndex = dndItems.findIndex(
      ({ element }, index) => index !== dragIndex && isHover(event, element)
    );
    // ホバーされている要素があれば、ドラッグしている要素と入れ替える
    if (hoveredIndex !== -1) {
      // カーソルの位置を更新
      state.pointerPosition.x = clientX;
      state.pointerPosition.y = clientY;
      // 要素を入れ替える
      let temp = dndItems[dragIndex];
      dndItems[dragIndex] = dndItems[hoveredIndex];
      dndItems[hoveredIndex] = temp;
      // ドラッグ要素の座標を更新
      dragElement.position = { x, y };
      // 再描画する
      setMemberInfo(dndItems.map((v) => v.value));
    }
  };

  // ドラッグが終了した時の処理
  const onTouchEnd = (event: TouchEvent) => {
    const { dragElement } = state;
    // ドラッグしていなかったら何もしない
    if (!dragElement) return;
    const dragStyle = dragElement.element.style;
    // ドラッグしてる要素に適用していたCSSを削除
    dragStyle.zIndex = "";
    dragStyle.cursor = "";
    dragStyle.transform = "";
    // ドラッグしている要素をstateから削除
    state.dragElement = null;
    // windowに登録していたイベントを削除
    window.removeEventListener("touchend", onTouchEnd);
    window.removeEventListener("touchmove", onTouchMove);
  };

  return memberInfo.map(
    (value: MemberInfo): DnDSortResult<MemberInfo> => {
      // keyが無ければ新しく作り、あれば既存のkey文字列を返す
      const key = state.keys.get(value) || Math.random().toString(16);
      // 生成したkey文字列を保存
      state.keys.set(value, key);
      return {
        value,
        key,
        isTouched,
        events: {
          ref: (element: HTMLElement | null) => {
            if (!element) return;
            const { dndItems, dragElement } = state;
            // 位置をリセットする
            element.style.transform = "";
            // 要素の位置を取得
            const { left: x, top: y } = element.getBoundingClientRect();            
            const position: Position = { x, y };
            // 要素が無ければ新しく追加して処理を終わる
            const itemIndex = dndItems.findIndex((item) => item.key === key);
            if (itemIndex === -1) {
              return dndItems.push({ key, value, element, position });
            }
            // sessionstorageとfirestoreへの保存（他ユーザがURL共有された際はdndItemsに値はないため条件をつけている）
            if(dndItems){
              // sessionstorageに値を保存する
              updatePositionInSessionStorage(dndItems);
              // Firestoreに座標の情報を保存
              // savePositionToFirestore( dndItems, isTouched );
            }
            // ドラッグ要素の時は、ズレを修正する
            if (dragElement?.key === key) {
              // ドラッグ要素のズレを計算する
              const dragX = dragElement.position.x - position.x;
              const dragY = dragElement.position.y - position.y;
              // 入れ替え時のズレを無くす
              element.style.transform = `translate(${dragX}px,${dragY}px)`;
            }

            // ドラッグ要素以外の要素をアニメーションさせながら移動させる
            if (dragElement?.key !== key) {
              const item = dndItems[itemIndex];
              // 前回の座標を計算
              const x = item.position.x - position.x;
              const y = item.position.y - position.y;
              // 要素を前回の位置に留めておく
              element.style.transition = "";
              element.style.transform = `translate(${x}px,${y}px)`;
              // 一フレーム後に要素をアニメーションさせながら元に位置に戻す
              requestAnimationFrame(() => {
                element.style.transform = "";
                element.style.transition = "all 300ms";
              });
            }
            // 要素を更新する
            state.dndItems[itemIndex] = { key, value, element, position };
          },

          // 要素をtouchし始めた時の処理
          onTouchStart: (event: React.TouchEvent) => {
            // マウスポインターの座標を保持しておく
            state.pointerPosition.x = event.touches[0].clientX;
            state.pointerPosition.y = event.touches[0].clientY;
            // Touchされたらアイコンの色が変わるようにフラグを立てる   
            setIsTouched(true);
            // 他ユーザにURL共有した際にもタッチされたかの状態が共有されるようにsessionストレージに保存
            sessionStorage.setItem("isTouched", "true");
            // firestoreにタッチされたステータスを格納
            // const db = getFirestore();
              // 現在のURLのパスを取得
            // const currentPath = window.location.pathname;
            // const partyIndex = currentPath.indexOf("party");
            // const partyId = currentPath.slice(partyIndex + "party/".length);
            // const partyDocRef = doc(db, "party", partyId as string);
            // updateDoc(partyDocRef, {"isTouched": true})
            // Touch中に画面が動かないようにデフォルトのタッチイベントの動作をキャンセル
            window.addEventListener("touchmove", onTouchStart, { passive: false });
          },

          // 要素をtouchしている最中の処理
          onTouchMove: (event: React.TouchEvent<HTMLElement>) => {
            // デフォルトのタッチイベントの動作をキャンセル
            //event.preventDefault();
            // ドラッグする要素(DOM)
            const element = event.currentTarget;
            // ドラッグしている要素のスタイルを上書き
            element.style.transition = ""; // アニメーションを無効にする
            element.style.cursor = "pointer"; // カーソルのデザインを変更
            // 要素の座標を取得
            const { left: x, top: y } = element.getBoundingClientRect();
            const position: Position = { x, y };
            // ドラッグする要素を保持しておく
            state.dragElement = { key, value, element, position };
            // mousemove, mouseupイベントをwindowに登録する
            window.addEventListener("touchend", onTouchEnd);
            window.addEventListener("touchmove", onTouchMove);
          },
          // 要素をtouchし終わった際に処理
          onTouchEnd: (event: React.TouchEvent) => {
            // Touch終わったらアイコンの色が変わるようにフラグを立てる   
            setIsTouched(false);
            // 他ユーザにURL共有した際にもタッチされたかの状態が共有されるようにsessionストレージに保存
            sessionStorage.setItem("isTouched", "false");
            // firestoreにタッチされたステータスを格納
            // const db = getFirestore();
            // 現在のURLのパスを取得
            // const currentPath = window.location.pathname;
            // const partyIndex = currentPath.indexOf("party");
            // const partyId = currentPath.slice(partyIndex + "party/".length);
            // const partyDocRef = doc(db, "party", partyId as string);
            // updateDoc(partyDocRef, {"isTouched": false})
            // Touch終わったらデフォルトのタッチイベントの動作が出来るようにする
            window.removeEventListener("touchstart", onTouchStart);
          }
        }
      };
    }
  );
};

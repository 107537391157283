import { Link } from 'react-router-dom';
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useRecoilState } from 'recoil';
import { memberState } from '../../../store/memberState';
// import db from "../../../firebase";
// import { collection, addDoc, setDoc, doc } from "firebase/firestore";

//typescriptのためのpropsの型定義
type ButtonType = {
    buttonName: string;
    linkName: string;
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#1976d2',
        color: theme.palette.common.white,
    },
    buttonText: {
        textTransform: 'lowercase',
        fontSize: '20px',
    },
}));

export const TransitionButton = (props: ButtonType) => {
    const { buttonName, linkName } = props;
    const [memberInfo] = useRecoilState(memberState);
    const classes = useStyles();
    // firestoreにユーザ情報登録
    // const createParty = async () => {
    //     try {
    //     // firestore使用ときのコード
    //       const partyRef = await addDoc(collection(db, "party"), {}); 
    //       console.log(partyRef)
    //       const partyId = partyRef.id; 
    //       // memberInfo サブコレクションを作成
    //       const memberInfoCollectionRef = collection(db, "party", partyId, "memberInfo");
    //       // memberInfo配列の各ユーザ情報をドキュメントとして格納
    //       for (const data of memberInfo) {
    //         // await addDoc(memberInfoCollectionRef, data);
    //         await setDoc(doc(memberInfoCollectionRef, data.id.toString()), data);
    //       }
    //       // 他ユーザにURL共有した際にもpartyIdを参照できるようにsessionストレージにpartyIdを保存
    //       sessionStorage.setItem("partyId", partyId);
    //       // ダイナミックなURLを返す
    //       return `/party/${partyId}`; 
    //     } catch (error) {
    //       console.error("ルームの作成に失敗しました", error);
    //       // エラー時は空のURLを返す
    //       return '';
    //     }
    // };
    // DynamicURL生成関数
    const generateRandomString = (length: any) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
        return result;
    }

    // 結果ボタンを押した際の処理
    const handleButtonClick = async (event: any) => {
        if (buttonName === 'はじめる') {
            sessionStorage.removeItem("partyId");
            sessionStorage.removeItem("isTouched");
        }
        if (buttonName === '結果') {
            // 登録ユーザが2人以下の場合に警告
            if( memberInfo.length < 2){
                alert('メンバーを2人以上追加してください。');
                event.stopPropagation();
                return
            }
            let isTouched: any = sessionStorage.getItem("isTouched");
            if(!isTouched){
                isTouched = "false";
            }
            // ダイナミックURLで遷移
            // const url = await createParty();
            const randomString = generateRandomString(20); // 20文字のランダムな文字列を生成
            // 他ユーザにURL共有した際にもpartyIdを参照できるようにsessionストレージにpartyIdを保存
            sessionStorage.setItem("partyId", randomString);
            const url =  `/party/${randomString}`; 
            sessionStorage.setItem("isTouched", isTouched);
            window.location.href = url;
            // if (url) {
            //     sessionStorage.setItem("isTouched", isTouched);
            //     // ページ遷移
            //     window.location.href = url;
            // } else {
            //     console.error("ルームの作成に失敗しました");
            // }
        }
    };
    return(
        <>
            <Button
                variant="contained"
                className={classes.button}
                component={buttonName === '結果' && memberInfo.length < 2 ? 'button' : Link}
                to={buttonName === '結果' ? '#' : linkName}
                TouchRippleProps={{ center: true }} 
                endIcon={<ArrowCircleRightIcon style={{ fontSize: '30px' }} />}
                onClick={handleButtonClick}
            >
                <span className={classes.buttonText}>{buttonName}</span>
            </Button>
        </>
    )
}
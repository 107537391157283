import { atom } from "recoil";

export type UserInfo = {
    id: number;
    name: string;   
    type: any;
    position: any;
};

export const userState = atom({
    key: 'userState',
    default: { id: Math.random(), name: '', type: 'primary', position: null },
});